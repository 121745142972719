.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}
.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}

#experience .title::after {
  content: "What I Know";
}

.experience__details {
  display: flex;
  gap: 1rem;
}

.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

.progress-bar-95 {
  position: relative;
  width: 5rem;
  height: 0.3rem;
  background: var(--color-light);
  border-radius: 1rem;
  overflow: hidden;
}

.progress-bar-95::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-secondary);
  transform: translateX(-100%);
  animation: fill-bar-95 0.8s 0.3s ease-in-out forwards;
}

.progress-bar-90 {
  position: relative;
  width: 5rem;
  height: 0.3rem;
  background: var(--color-light);
  border-radius: 1rem;
  overflow: hidden;
}

.progress-bar-90::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-secondary);
  transform: translateX(-100%);
  animation: fill-bar-90 0.8s 0.3s ease-in-out forwards;
}
.progress-bar-80 {
  position: relative;
  width: 5rem;
  height: 0.3rem;
  background: var(--color-light);
  border-radius: 1rem;
  overflow: hidden;
}

.progress-bar-80::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-secondary);
  transform: translateX(-100%);
  animation: fill-bar-80 0.8s 0.3s ease-in-out forwards;
}
.progress-bar-70 {
  position: relative;
  width: 5rem;
  height: 0.3rem;
  background: var(--color-light);
  border-radius: 1rem;
  overflow: hidden;
}

.progress-bar-70::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-secondary);
  transform: translateX(-100%);
  animation: fill-bar-70 0.8s 0.3s ease-in-out forwards;
}
.progress-bar-60 {
  position: relative;
  width: 5rem;
  height: 0.3rem;
  background: var(--color-light);
  border-radius: 1rem;
  overflow: hidden;
}

.progress-bar-60::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-secondary);
  transform: translateX(-100%);
  animation: fill-bar-60 0.8s 0.3s ease-in-out forwards;
}

@keyframes fill-bar-95 {
  to {
    transform: translateX(-5%);
  }
}
@keyframes fill-bar-90 {
  to {
    transform: translateX(-10%);
  }
}
@keyframes fill-bar-80 {
  to {
    transform: translateX(-20%);
  }
}
@keyframes fill-bar-70 {
  to {
    transform: translateX(-30%);
  }
}
@keyframes fill-bar-60 {
  to {
    transform: translateX(-40%);
  }
}

/* -------------------------------------------------------------------------- */
/*                       MEDIA QUERIES (MEDIUM DEVICES)                       */
/* -------------------------------------------------------------------------- */

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }
  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }
  .experience__content {
    padding: 1rem;
  }
}

/* -------------------------------------------------------------------------- */
/*                        MEDIA QUERIES (SMALL DEVICES)                       */
/* -------------------------------------------------------------------------- */

@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
  }
  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
}
